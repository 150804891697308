import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, InputGroup, Image, Card, Container, Row, Col, Button, Tabs, Tab, Modal } from 'react-bootstrap';
import { Play, Search, FileText, Image as ImageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import RecordingInfoModal from './RecordingInfoModal';
import TagsBrowser from './TagsBrowser';
import MediaViewer from './MediaViewer';
import { PageTitle } from '../common/PageTitle';

const RecordingsView = ({
  searchQuery,
  setSearchQuery,
  filteredRecordings,
  handleShowInfo,
  handleShowMedia,
  handlePlayRecording,
  recordingsWithMedia,
  getPhotoUrl
}) => {
  const searchInputRef = useRef(null);

  return (
    <>
      <div className="position-relative mb-4">
        <InputGroup className="mb-3">
          <Form.Control
            ref={searchInputRef}
            type="text"
            placeholder="Szukaj ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputGroup.Text>
            <Search size={20} />
          </InputGroup.Text>
        </InputGroup>
      </div>

      <Row>
        {filteredRecordings.map(recording => (
          <Col md={6} lg={4} className="mb-4" key={recording.metadata.signature}>
            <Card className="h-100">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-3 flex-grow-1">
                    {recording.metadata.interviewee?.photo && (
                      <Image
                        src={getPhotoUrl(recording)}
                        alt={`Zdjęcie: ${recording.metadata.interviewee?.name}`}
                        className="rounded-circle object-cover shadow-sm"
                        style={{
                          width: '64px',
                          height: '64px',
                          objectFit: 'cover'
                        }}
                      />
                    )}
                    <div className="flex-grow-1">
                      <h5 className="mb-2">{recording.metadata.interviewee?.name}</h5>
                      <p className="text-muted mb-2">{recording.metadata.title}</p>
                      <small className="text-muted">
                        Data nagrania: {new Date(recording.metadata.date).toLocaleDateString('pl-PL')}
                      </small>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <Button
                      variant="primary"
                      className="rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: '48px', height: '48px', padding: 0 }}
                      onClick={() => handleShowInfo(recording)}
                      title="Informacje"
                    >
                      <FileText size={24} />
                    </Button>
                    {recordingsWithMedia.has(recording.metadata.signature) && (
                      <Button
                        variant="primary"
                        className="rounded-circle d-flex align-items-center justify-content-center"
                        style={{ width: '48px', height: '48px', padding: 0 }}
                        onClick={() => handleShowMedia(recording)}
                        title="Media"
                      >
                        <ImageIcon size={24} />
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      className="rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: '48px', height: '48px', padding: 0 }}
                      onClick={() => handlePlayRecording(recording)}
                      title="Odtwórz"
                    >
                      <Play size={24} />
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

const ListeningModule = () => {
  const [recordings, setRecordings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredRecordings, setFilteredRecordings] = useState([]);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [recordingsWithMedia, setRecordingsWithMedia] = useState(new Set());
  const [activeTab, setActiveTab] = useState('recordings');
  const navigate = useNavigate();

  const checkMediaForRecording = useCallback(async (recording) => {
    try {
      const [photosRes, docsRes] = await Promise.all([
        fetch(`/api/media.php?recordingId=${recording.metadata.signature}&type=photos&action=metadata`, {
          credentials: 'include'
        }),
        fetch(`/api/media.php?recordingId=${recording.metadata.signature}&type=documents&action=metadata`, {
          credentials: 'include'
        })
      ]);

      const [photos, docs] = await Promise.all([
        photosRes.json(),
        docsRes.json()
      ]);

      if ((photos.data?.length > 0 || docs.data?.length > 0)) {
        setRecordingsWithMedia(prev => new Set([...prev, recording.metadata.signature]));
      }
    } catch (error) {
      console.error('Error checking media:', error);
    }
  }, []);

  const fetchRecordings = useCallback(async () => {
    try {
      const response = await fetch('/api/recordings');
      const data = await response.json();
      const publicRecordings = data.data.filter(recording =>
        recording.access?.restrictions === 'public'
      );
      setRecordings(publicRecordings);
      setFilteredRecordings(publicRecordings);

      // Check media for each recording
      publicRecordings.forEach(recording => {
        checkMediaForRecording(recording);
      });
    } catch (error) {
      console.error('Error fetching recordings:', error);
    }
  }, [checkMediaForRecording]);

  useEffect(() => {
    fetchRecordings();
  }, [fetchRecordings]);

  useEffect(() => {
    const filtered = recordings.filter(recording =>
      recording.access?.restrictions === 'public' && (
        recording.metadata.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        recording.metadata.interviewee?.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredRecordings(filtered);
  }, [searchQuery, recordings]);

  const handlePlayRecording = (recording) => {
    navigate(`/library/recording/${recording.metadata.signature}`);
  };

  const handleShowInfo = (recording) => {
    setSelectedRecording(recording);
    setShowInfoModal(true);
  };

  const handleShowMedia = (recording) => {
    setSelectedRecording(recording);
    setShowMediaModal(true);
  };

  const getPhotoUrl = (recording) => {
    if (recording.metadata.interviewee?.photo) {
      return `/storage/recordings/${recording.metadata.signature}/${recording.metadata.interviewee.photo}`;
    }
    return null;
  };

  return (
    <>
      <PageTitle title="Biblioteka" />
      <Container>
        <Row className="mb-4">
          <Col>
            <h2 className="mb-4">Historia mówiona</h2>
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-4"
            >
              <Tab eventKey="recordings" title="Nagrania">
                <RecordingsView
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  filteredRecordings={filteredRecordings}
                  handleShowInfo={handleShowInfo}
                  handleShowMedia={handleShowMedia}
                  handlePlayRecording={handlePlayRecording}
                  recordingsWithMedia={recordingsWithMedia}
                  getPhotoUrl={getPhotoUrl}
                />
              </Tab>
              <Tab eventKey="tags" title="Indeksy">
                <div>
                  <TagsBrowser />
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <RecordingInfoModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          recording={selectedRecording}
        />

        <Modal
          show={showMediaModal}
          onHide={() => setShowMediaModal(false)}
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Media - {selectedRecording?.metadata.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRecording && (
              <MediaViewer recordingId={selectedRecording.metadata.signature} />
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default ListeningModule;
