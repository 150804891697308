import React from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import VideoPlayer from './VideoPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faClock, faPeopleLine } from '@fortawesome/free-solid-svg-icons';

import { PageTitle } from '../common/PageTitle';

function Public() {
  return (
    <>
      <PageTitle title="Start" />
      <div className="bg-primary text-white py-5 mb-5 px-5">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h1 className="display-4 fw-bold">Pomiechowska Biblioteka Historii Mówionej</h1>
            <p className="lead">
              Odkrywamy i&nbsp;zachowujemy historię naszej gminy poprzez wspomnienia jej mieszkańców.
            </p>
            <Button
              variant="light"
              size="lg"
              className="my-3"
              as="a"
              href="/about"
            >
              Podziel się swoją historią
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <Carousel>
              <Carousel.Item>
                <img
                  src="/img/img20241215_22501558.jpg"
                  alt="Pierwsze zdjęcie Pomiechówka"
                  className="img-fluid rounded shadow"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src="/img/img20241215_22444368.jpg"
                  alt="Drugie zdjęcie Pomiechówka"
                  className="img-fluid rounded shadow"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src="/img/img20241215_22551416.jpg"
                  alt="Trzecie zdjęcie Pomiechówka"
                  className="img-fluid rounded shadow"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>

      {/* Project Info Section with Parallax Background */}
      <div 
        className="py-5 mb-5" 
        style={{
          backgroundImage: 'url(/img/tlo-biblioteka.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed', // Efekt paralaksy
          position: 'relative',
          isolation: 'isolate',
          minHeight: '500px', // Minimalna wysokość dla lepszego efektu paralaksy
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(135deg, rgba(112, 53, 17, 0.65), rgba(112, 53, 17, 0.55))',
            mixBlendMode: 'multiply',
            zIndex: 1
          }}
        />
        <Container className="position-relative" style={{ zIndex: 2 }}>
          <Row className="justify-content-center text-center">
            <Col md={8} className="text-white">
              <h2 className="display-4 mb-4">Zachowujemy historię</h2>
              <p className="lead mb-4">
                Biblioteka Historii Mówionej to unikalne archiwum wspomnień, opowieści,
	ale też związanych z&nbsp;nimi fotografii i&nbsp;dokumentów, 
                które tworzą żywą historię naszej gminy. Każda zarejestrowana opowieść 
                to bezcenny element naszego wspólnego dziedzictwa.
              </p>
		<p className="mb-4">
                Aby uzyskać pełny dostęp do udostępnionych materiałów archiwalnych,
	  wymagane jest bezpłatne konto użytkownika.
                Rejestracja jest zgodna z&nbsp;<a href="/terms"
	  className="text-white text-decoration-underline">regulaminem</a> projektu
                i pozwala na przeglądanie wszystkich udostępnianych zasobów.
              </p>
	        <div className="d-flex justify-content-center gap-3">
                <Button as="a" href="/login" variant="light" size="lg">
                  Zaloguj się
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mb-5">
        <Row className="bg-secondary-subtle border rounded p-5 align-items-center">
          <Col md={8}>
	  <h1 className="mb-3">Masz już konto?</h1>
		<p className="mt-4 fs-5">Jesteś zalogowany? <br/> Przejdź od razu do biblioteki historii mówionej
</p>
          </Col>
          <Col md={4} className="text-md-end">
            <Button as="a" href="/library" variant="primary" size="lg">
              Posłuchaj opowieści
            </Button>
          </Col>
        </Row>
      </Container>

      {/* O Projekcie Section */}
      <Container className="mb-5">
        <Row>
          <Col lg={6}>
            <h2 className="mb-4">O naszym projekcie</h2>
                <img
                  src="/img/pbhm-logo-3.svg"
                  alt="PBHM logo"
                  className="w-75 img-fluid"
                />
            <p className="lead">
              Pomiechowska Biblioteka Historii Mówionej to projekt mający na celu zachowanie
              pamięci o&nbsp;historii naszej gminy poprzez nagrywanie wspomnień jej mieszkańców.
            </p>
            <p>
              Gromadzimy wywiady, zdjęcia i&nbsp;dokumenty, które pozwalają nam lepiej poznać
              i&nbsp;zrozumieć historię naszej małej ojczyzny. Każda opowieść jest cennym
              świadectwem przeszłości&nbsp;i&nbsp;buduje naszą wspólną tożsamość.
            </p>
            <Button as="a" href="/about" variant="primary" className="my-3">Dowiedz się więcej</Button>
          </Col>
          <Col lg={6}>
            <Card className="h-100 border-0 shadow-lg">
              <Card.Body>
                <h3 className="mb-4">Dołącz do nas</h3>
                <ul className="list-unstyled">
                  <li >✓ Podziel się swoimi wspomnieniami</li>
                  <li >✓ Udostępnij rodzinne fotografie</li>
                  <li >✓ Zostań wolontariuszem</li>
                  <li >✓ Pomóż w zbieraniu historii</li>
                </ul>
	     <VideoPlayer
              videoSrc="/videos/Andrzej-apel.mp4"
              posterSrc="/videos/Andrzej-apel.png"
              maxWidth="1000px"
              aspectRatio="16:9"
              head="Andrzej Szczęsny"
	      background="bg-primary"
	      text="text-light"
              title="Apel o dołączanie do projektu"
            />
            <Button as="a" href="/join" variant="primary" className="mt-3">Dołącz do projektu</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

	      <Container className="py-5">
      <Row className="text-center">
        <Col md={4} className="mb-4 mb-md-0">
          <Card className="h-100 border-0 shadow py-4">
            <Card.Body>
              <FontAwesomeIcon
                icon={faUserGroup}
                className="text-primary mb-3"
                style={{ fontSize: '3rem' }}
              />
              <h3 className="display-4 fw-bold mb-2">30+</h3>
              <p className="text-muted mb-0 fs-4">Przeprowadzonych rozmów</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4 mb-md-0">
          <Card className="h-100 border-0 shadow py-4">
            <Card.Body>
              <FontAwesomeIcon
                icon={faClock}
                className="text-primary mb-3"
                style={{ fontSize: '3rem' }}
              />
              <h3 className="display-4 fw-bold mb-2">30</h3>
              <p className="text-muted mb-0 fs-4">Godzin nagrań</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 border-0 shadow py-4">
            <Card.Body>
              <FontAwesomeIcon
                icon={faPeopleLine}
                className="text-primary mb-3"
                style={{ fontSize: '3rem' }}
              />
              <h3 className="display-4 fw-bold mb-2">∞</h3>
              <p className="text-muted mb-0 fs-4">Historii do opowiedzenia</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

      {/* Video Section */}
      <Container className="mb-5">
        <h2 className="text-center mb-4">Historie naszych mieszkańców</h2>
        <Row>
          <Col lg={6} className="mb-4">
            <VideoPlayer
              videoSrc="/videos/Prawda-wojna-poczatek-ok.mp4"
              posterSrc="/videos/pbhm-logo-l-1.png"
              maxWidth="1000px"
              aspectRatio="3.13:1"
              head="Bronisław Prawda"
              title="Wspomnienie z początku wojny"
            />
          </Col>
          <Col lg={6} className="mb-4">
            <VideoPlayer
              videoSrc="/videos/Nowinska-czolg-ok.mp4"
              posterSrc="/videos/pbhm-logo-l-1.png"
              maxWidth="1000px"
              aspectRatio="3.13:1"
              head="Anastazja Nowińska"
              title="Powojenny wypadek z rosyjskim czołgiem"
            />
          </Col>
        </Row>
      </Container>

      {/* Contact Section */}
      <Container className="mb-5">
        <Row className="bg-secondary-subtle border rounded p-5 align-items-center">
          <Col md={8}>
            <h2>Masz historię do opowiedzenia?</h2>
            <p className="lead mb-0">
              Skontaktuj się z nami i pomóż nam zachować historię Pomiechówka.
            </p>
          </Col>
          <Col md={4} className="text-md-end">
            <Button as="a" href="/contact" variant="primary" size="lg">
              Skontaktuj się
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Public;
